@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../style/variables.scss";

.livret {
    p {
        text-align: justify;
    }

    .img-at-left {
        max-width: 100%;
        @include media-breakpoint-up(md) {
            float: left;
            margin: 0 1em 1em 0;
            max-width: 58%;
        }
    }

    .side-by-side-img {
        img {
            @include media-breakpoint-up(md) {
                vertical-align: middle;
                &:first-child {
                    max-width: 59%;
                    margin-right: 2%;
                }
                &:last-child {
                    max-width: 39%;
                }
            }
        }
    }

    .address {
        padding: 0.5em 1em;
        background-color: $accent-color-light;
        border-radius: 9px;
        max-width: 450px;
        margin: 0 auto;
        font-weight: bold;

        em {
            font-weight: normal;
        }
    }

    .chapter {
        overflow: hidden;
    }
}