@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@import "style/variables.scss";

.main-container {
	margin: 0 1em;

	@include media-breakpoint-up(lg) {
		max-width: map-get($container-max-widths, "lg");
		margin: 0 auto;
	}
}

.main-nav-placeholder {
	@include media-breakpoint-up(lg) {
		float: right;
		height: 100vh;
		width: $center-img-side-in;
	}
}

.main-nav-container {
	background-color: rgb(0, 0, 0, 0.85);
	position: fixed;
	width: calc(100vw - #{$main-nav-icon-size});
	transition: right 1.2s;
	z-index: 10000; // to be sure to be above the map
	border-radius: 0 0 0 6px;
	right: 0;

	&.swiped {
		right: calc(-100vw + #{$main-nav-icon-size});
	}

	@include media-breakpoint-up(md) {
		width: 50vw;

		&.swiped {
			right: -50vw;
		}
	}

	@include media-breakpoint-up(lg) {
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: calc(((100vw - ((100vh * 2) / 3) ) / 2 - #{$main-nav-icon-size}));
		min-width: 260px;
		border-radius: O;
		background: none;

		&.swiped {
			right: 0;
		}
	}
	
	.nav-icon {
		position: absolute;
		line-height: 1;
		height: calc($main-nav-icon-size + 20px);
		left: -$main-nav-icon-size;
		top: 0;
		border-radius: 6px 0 0 6px;
		background-color: rgb(0, 0, 0, 0.85);
		color: #ffffff;
		font-size: $main-nav-icon-size;
		font-weight: bold;
		text-align: center;
		cursor: pointer;

		&-title {
			font-size: 10px;
		}
	
		svg {
			vertical-align: baseline;
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}