@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../style/variables.scss";

.map-container {
	position: relative;
	overflow: hidden;
}

.leaflet-container {
	width: 100%;
	height: 70vh;
  
	@include media-breakpoint-only(md) {
	  height: 50vh;
	}
}

.map-legends-icon {
	display: none;
}
@include media-breakpoint-down(sm) {
	.map-legends {
		position: absolute;
		top: 0;
		left: -90%;
		// height: 100%;
		width: calc(100% - 36px);
		z-index: 9999;
		background: rgb(255, 255, 255, 0.85);
		transition: left 1s;

		&.displayed {
			left: 0;
		}

		&-icon {
			display: block;
			padding: 8px 0;
			text-align: center;
			position: absolute;
			left: 100%;
			bottom: 0;
			width: 36px;
			background: rgb(255, 255, 255, 0.85);
			border-radius: 0 6px 0 0;

			&-text {
				text-transform: uppercase;
				font-size: 9px;
				font-weight: bold;
				writing-mode: tb-rl;
				transform: rotate(-180deg);
			}
		}
	}
}

.map-legend {
	margin: 0.5em 0;
	clear: both;
	img {
		height: 25px;
		display: inline-block;
		vertical-align: middle;
	}

	&-text {
		white-space: normal;
		margin-left: 5px;
		display: inline-block;
		vertical-align: middle;
		width: 120px;
	}

	&-header {
		float: left;
		width: 150px;
		white-space: nowrap;
	}
	&-markers {
		overflow: hidden;
		line-height: 1.5;
	}

	&-marker {
		display: inline-block;
		line-height: normal;
		vertical-align: middle;
		cursor: pointer;
		margin-left: 0.5em;
		background-color: $accent-color-md;
		color: #ffffff;
		border-radius: 3px;
		padding: 2px 6px;

		&.selected {
			background-color: $accent-color;
		}

		&-close {
			display: inline-block;
			vertical-align: text-bottom;
			margin-left: 0.4em;
			line-height: 0.75;
		}
	}
}