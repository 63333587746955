@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../style/variables.scss";

.main-header {
  height: 100vh;
  background: #000000 url(../assets/home.jpg) no-repeat center;
  background-size: auto 100%;
  margin: 0 -1em;

  @include media-breakpoint-only(md) {
    background-size: 100% auto;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 calc((-100vw + #{map-get($container-max-widths, "lg")}) / 2);
  }

  .title-container {
    position: absolute;
    bottom: 20px;
    right: 0px;
    text-align: right;
    width: 350px;
    font-size: 0.7em;

    @include media-breakpoint-up(md) {
      bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      bottom: auto;
      right: calc(((100vw - ((100vh * 2) / 3)) / 2) + ((100vh * 2) / 3));
    }
  }
}

.home-content {
  margin-bottom: 60vh;

  @include media-breakpoint-up(lg) {
    padding-right: calc((#{map-get($container-max-widths, "lg")} - ((100vh * 2) / 3)) / 2);
  }
}

.home-block-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.1em;
  padding: 2em 0;

  .home-block {
    position: relative;
    flex: 1;
    flex-basis: 100%;
    background: $accent-color-md;
    color: #ffffff;

    &:before{
      content: "";
      display: block;
      padding-top: 100%;
    }

    &:nth-child(2) {
      background-color: $accent-color;
    }
    &-inner {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: calc(100% - 4em);
      left: 2em;
    }

    a {
      color: #ffffff;
      text-decoration: underline;
    }

    ul {
      margin: 0;
      padding: 0.8em;
      list-style: none;

      li {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0px;
          height: 24px;
          left: -0.8em;
          width: 1px;
          background-image: $accent-color-light-gradiant-bg;
        }
        & + li {
          margin-top: 0.4em;
        }
        
      }
    }

    @include media-breakpoint-up(lg) {
      flex-basis: calc(50% - 1em);
    }
  }
}