@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../style/variables.scss";

.main-nav {
    position: relative;
    line-height: 1.2;
    padding: 6px 12px;
    color: #fff;
    min-height: 80vh;

    @include media-breakpoint-up(lg) {
        width: 260px;
        border-radius: 6px;
        background-color: rgb(0, 0, 0, 0.85);
    }

    .logo {
        margin-bottom: 1em;

        &-container {
            display: block;
            font-size: 0.5em;
            opacity: 1;
            transition: opacity 2s;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        ul {
            padding: 0.5em 0 0 2em;

            li {
                font-size: 16px;
                & + li {
                    margin-top: 0.3em;
                }
            }
        }
    }

    li {
        position: relative;
        padding: 0;
        font-size: 20px;

        & + li {
            margin-top: 0.5em;
        }

        &.pwa-item {
            display: none;
            margin-top: 1em;

            &.active {
                display: list-item;
            }

            button {
                box-shadow: none;
                background-color: #fff;
                border: none;
                color: #000;
                border-radius: 3px;
                padding: 3px 6px;
                font-size: 16px;
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
            color: $accent-color-md;
        }
    }

    .home-speach {
        position: absolute;
        bottom: 2em;
        width: 280px;
        margin-right: 1.5em;
        font-size: 1em;
        color: $accent-color-md;
        text-align: justify;
        opacity: 1;
        transition: opacity 2s;
    }
}

@include media-breakpoint-up(lg) {
    .main-nav.for-home {
        .logo-container {
            opacity: 0;

            .swiped & {
                opacity: 1;
            }
        }

        nav > ul > li {
            &::before {
                content: "";
                position: absolute;
                top: 0px;
                height: 24px;
                left: -1em;
                width: 1px;
                background-image: $accent-color-light-gradiant-bg;
            }

            &.pwa-item::before {
                display: none;
            }
        }
    }

}

.swiped .main-nav .home-speach {
    opacity: 0;
}