@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../style/variables.scss";

.logo {
	text-align: right;
	line-height: 1.1;

	img {
		float: right;
		height: 6.03em;
		margin: .26em 0 0 .5em;

		@include media-breakpoint-up(md) {
			height: 9.1em;
			margin: .4em 0 0 1em;
		}
	}
}

.title {
	&-wrapper {
		overflow: hidden;
	}
	margin: 0;
	text-transform: uppercase;
	font-weight: bold;
	font-family: Righteous;
	font-size: 2em;

	@include media-breakpoint-up(md) {
		font-size: 3em;
	}
}

.sub-title {
	font-size: 1em;
	font-family: Righteous;
	color: $accent-color;
}

.number {
	&.n43 {
		position: relative;
		padding-right: 3px;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 1px;
			background-image: $accent-color-light-gradiant-bg;
		}
	}
	&.n45 {
		padding-left: 2px;
	}
}

.esperluette {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
}