@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$main-text-color: #333;
$accent-color: #715c86; // #8944df;
$accent-color-xl: #7526c5;
$accent-color-xxl: #7c00f8;
$accent-color-md: #9d8fb9;
$accent-color-light: #e9e7ea;
$accent-color-light-gradiant-bg: linear-gradient(to bottom, rgba(231, 232, 234, 0), rgba(231, 232, 234, 1), rgba(231, 232, 234, 0));

$sm: map-get($grid-breakpoints, "sm");
$md: map-get($grid-breakpoints, "md");
$lg: map-get($grid-breakpoints, "lg");

$center-img-width: (100vh * 2) / 3;
$center-img-side-in: calc((#{$lg} - (100vh * 2) / 3) / 2);

$main-nav-icon-size: 36px;