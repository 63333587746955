@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";


.mosaic {
	max-width: 100%;
	margin: 0 0 1em 0;

	@include media-breakpoint-up(md) {
		float: left;
		max-width: 33.33%;
		margin-right: 1em;
	}
}