@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Monoton&family=Righteous&display=swap');

@import "./variables.scss";

/**
 * TODO rename this file or put it with index.html
 * TODO add right margin for page tile to let room for menu icon in small medium devices
 * TODO responsive for tabs
 */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family:'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:after, *:before {
  box-sizing: border-box;
}

a {
  color: $accent-color-xl;
  text-decoration: none;

  &:hover {
    color: $accent-color-xxl;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family:  Righteous;
}

h1 {
  text-align: center;
  text-transform: uppercase;
}


.accent {
  color: $accent-color;
}
.accent-md {
  color: $accent-color-md;
}
.accent-light {
  color: $accent-color-light;
}

.page {
  position: relative;
  margin-bottom: 90vh;
  overflow: hidden;

  .chapter {
    scroll-margin-top: calc($main-nav-icon-size + 32px);
  }

  p {
		text-align: justify;
	}
}


